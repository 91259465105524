import React, { Fragment, useContext, useEffect } from "react";
// import { useLocation } from "react-router-dom";

import HolidayContext from "../../context/holiday/holidayContext";
import LegContext from "../../context/leg/legContext";

// import { InputLabel, Select, MenuItem, FormControl } from "@mui/material";

const HolidayDropDown = ({ visible }) => {
  const holidayContext = useContext(HolidayContext);
  const {
    holidays,
    getHolidays,
    currentHoliday,
    setCurrentHoliday,
    clearCurrentHoliday,
    loading,
  } = holidayContext;

  const legContext = useContext(LegContext);
  const { clearLegs } = legContext;

  const options = {
    getFunction: getHolidays,
    collection: holidays,
    current: currentHoliday,
    setFunction: setCurrentHoliday,
    clearFunction: clearCurrentHoliday,
    checkQueryString: "select_library",
    notSelectedTitle: "Please select holiday",
    indexField: "_id",
    displayField: "name",
    title: "Holiday",
    showIfNoValue: true,
  };

  // const location = useLocation();

  useEffect(() => {
    if (!holidays) {
      //   let forceRefresh = false;
      options.getFunction();
    }
    // eslint-disable-next-line
  }, [currentHoliday?._id, holidays]);

  const setDDValue = (e) => {
    if (e.target.value === "new") {
      options.setFunction({ _id: "new", name: "New Holiday", edit: [] });
      clearLegs();
    } else {
      setTheCollection(e.target.value);
    }
  };

  const setTheCollection = (selectedOption) => {
    if (selectedOption && selectedOption !== "notSelected") {
      let collectionList = getCollectionObject(selectedOption);
      options.setFunction(collectionList);
      // getLegs(collectionList);
    } else {
      options.clearFunction();
    }
  };

  const getCollectionObject = (selectedOption) => {
    if (!options.collection) {
      return null;
    } else {
      let col = options.collection.filter(
        (collection) =>
          collection[options.indexField].toString() ===
          selectedOption.toString()
      );
      return col[0];
    }
  };

  // const getQueryElement = (queryString, queryName) => {
  //   queryString = decodeURIComponent(queryString);
  //   if (queryString && queryString.includes(queryName)) {
  //     queryString = queryString.substr(1, queryString.length - 1);
  //     let locations = queryString.split("&");
  //     let foundQueryPair = locations.filter(
  //       (loc) => loc.indexOf(queryName) === 0
  //     );
  //     if (!!foundQueryPair && foundQueryPair.length > 0) {
  //       let queryValue = foundQueryPair[0].split("=");
  //       return queryValue[1];
  //     }
  //   }
  //   return false;
  // };

  const selectNoValue = () => (
    <option key="notSelected-1" value="notSelected">
      No {options.title}
    </option>
  );
  const selectLoading = () => (
    <option key="notSelected-2" value="notSelected">
      loading ...
    </option>
  );

  const selectPleaseSelect = () => (
    <option key="notSelected-3" value="notSelected">
      {options.notSelectedTitle}
    </option>
  );

  const addNew = () => (
    <option key="newItem-3" value="new">
      New {options.title}
    </option>
  );

  const hasValues = options.collection !== null && options?.collection?.[0];
  const hasSelected = options.current;
  const selectedCollection = hasSelected ? options.current : null;

  // hasValues && hasSelected
  // ? currentHoliday._id
  // : // ? selectedCollection[options.indexField]
  //   options.notSelectedTitle

  return (
    <Fragment>
      {hasValues || options.showIfNoValue ? (
        <span>
          <select
            name="select_library"
            className="form-select form-select-sm"
            value={
              hasValues && hasSelected
                ? selectedCollection[options.indexField]
                : options.notSelectedTitle
            }
            onChange={(e) => {
              setDDValue(e);
            }}
          >
            {hasValues ? (
              <Fragment>{selectPleaseSelect()}</Fragment>
            ) : loading ? (
              selectLoading()
            ) : (
              selectNoValue()
            )}
            {addNew()}
            {hasValues
              ? options.collection.map((item, key) => (
                  <option
                    key={item[options.indexField] + "-" + key}
                    value={item[options.indexField]}
                  >
                    {item[options.displayField]}
                  </option>
                ))
              : ""}
          </select>
        </span>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default HolidayDropDown;
