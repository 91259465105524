import { DateTime } from "luxon";
import { emptyObj } from "./common";
import { dateFmts, dateFormats } from "../components/legs/legDefinition";

// context
// dates stored in db are jsdate
// different form sections require different date types
//      - summary uses "dd-MMM-yy hh:mm"
// different dates required for date picker
//      - flatdatepicker needs ISO
//      - html datetime needs ISO minus seconds & milliseconds
//
// application use:
//      - to use ISO date through functions
//      - convert to other formats as required
//
// functions required:
//      1. convert date from base format (jsdate) to display format
//      2. convert date from display format to iso format

// need two functions:-
//  1. convert date from controls to standard format - jsdate or ISO
//  2. convert date for controls from standard format

export const convertDateFrom = (value, type, format, options) => {
  let date;
  if (emptyObj(options)) options = {};
  // console.log(type);
  switch (type.toLowerCase()) {
    case "iso":
      date = new DateTime.fromISO(value, options);
      break;
    case "jsdate":
      date = new DateTime.fromJSDate(value, options);
      break;
    case "format":
    default:
      date = new DateTime.fromFormat(value, format, options);
      break;
  }
  // console.log(date.isValid);
  return date.isValid ? date : value;
};

export const convertDateTo = (date, type, format, options) => {
  if (!date.isValid) return date;
  if (emptyObj(options)) options = {};

  switch (type.toLowerCase()) {
    case "iso":
      return date.toISO(options);
    case "jsdate":
      return date.toJSDate(options);
    case "format":
    default:
      return date.toFormat(format, options);
  }
};

export const convertDateFromBase = (base, to, value, format, options) => {
  if (value === null || value === undefined) return "";
  let date = value;
  // console.log("**************");
  // console.log(date);
  // console.log(date.isValid);
  if (!date?.isValid) {
    date = convertDateFrom(value, base, "", {});
    if (!date.isValid) return null;
  }
  // console.log("------------");
  // console.log(date);
  // console.log(to);
  // console.log(format);
  // console.log(options);
  date = convertDateTo(date, to, format, options);
  // console.log(date);
  // console.log("------------");
  return date;
};

export const convertDateToBase = (base, from, value, format, options) => {
  base = "iso";
  if (value === null || value === undefined) return "";

  let date = convertDateFrom(value, from, format, options);
  if (!date.isValid) return null;

  date = convertDateTo(date, base, "", {});
  return date;
};

// export const convertDateToFrom = (base, toFrom, value, format) => {
//   let base = "iso";
//   if (value === null || value === undefined) return "";

//   let date = convertDateFrom(value, base, options, "")

//   let dt;
//   if (toFrom.toLowerCase() === "from") {
//     switch (format.toLowerCase()) {
//       case "iso":
//         dt = DateTime.fromISO(value);
//         break;
//       case "isoNoSecOrMS":
//         dt = DateTime.fromISO(value);
//         break;
//       case "jsdate":
//         dt = DateTime.fromJSDate(value);
//         break;
//       case "format":
//       default:
//         dt = DateTime.fromFormat(value, format);
//         break;
//     }
//     return dt.isValid ? dt.toISO() : dt;
//   } else {
//     dt = DateTime.fromISO(value);
//     if (!dt.isValid) return value;
//     switch (format.toLowerCase()) {
//       case "iso":
//         return dt.toISOvalue;
//       case "jsdate":
//         return dt.toJSDate();
//       case "format":
//       default:
//         return dt.toFormat(format);
//     }
//   }
// };

// export const convertDateISO = (toFrom, value, format) => {
//   if (value === null || value === undefined) return "";

//   let dt;
//   if (toFrom.toLowerCase() === "from") {
//     switch (format.toLowerCase()) {
//       case "iso":
//         dt = DateTime.fromISO(value);
//         break;
//       case "isoNoSecOrMS":
//         dt = DateTime.fromISO(value);
//         break;
//       case "jsdate":
//         dt = DateTime.fromJSDate(value);
//         break;
//       case "format":
//       default:
//         dt = DateTime.fromFormat(value, format);
//         break;
//     }
//     return dt.isValid ? dt.toISO() : dt;
//   } else {
//     dt = DateTime.fromISO(value);
//     if (!dt.isValid) return value;
//     switch (format.toLowerCase()) {
//       case "iso":
//         return dt.toISOvalue;
//       case "jsdate":
//         return dt.toJSDate();
//       case "format":
//       default:
//         return dt.toFormat(format);
//     }
//   }
// };

// export const convertDateJS = (toFrom, value, format) => {
//   if (value === null || value === undefined) return "";

//   let dt;
//   if (toFrom.toLowerCase() === "from") {
//     switch (format.toLowerCase()) {
//       case "iso":
//         dt = DateTime.fromISO(value);
//         break;
//       case "isoNoSecOrMS":
//         dt = DateTime.fromISO(value);
//         break;
//       case "jsdate":
//         dt = DateTime.fromJSDate(value);
//         break;
//       case "format":
//       default:
//         dt = DateTime.fromFormat(value, format);
//         break;
//     }
//     return dt.isValid ? dt.toJSDate() : dt;
//   } else {
//     dt = DateTime.fromJSDate(value);
//     if (!dt.isValid) return value;
//     switch (format.toLowerCase()) {
//       case "iso":
//         return dt.toISOvalue;
//       case "jsdate":
//         return dt.toJSDate();
//       case "format":
//       default:
//         return dt.toFormat(format);
//     }
//   }
// };

// date time functions

export const isISODate = (d) => {
  if (!d || d === undefined || d === "") return false;
  let newDate = DateTime.fromISO(d);
  return newDate.isValid;
};

export const isValidDate = (d, formatSuggested) => {
  if (!d || d === undefined || d === "") return false;
  let newDate = DateTime.fromISO(d);
  if (newDate.isValid) return newDate;
  newDate = formatSuggested
    ? checkValidDates(d, formatSuggested)
    : checkValidDates(d);
  return newDate.isValid;
};

export const checkValidDates = (d, formatSuggested) => {
  if (!d || d === undefined || d === "") return "";

  let newDate;
  if (d?.isValid) return d;
  if (formatSuggested) {
    if (formatSuggested.toLowerCase() === "jsdate") {
      newDate = DateTime.fromJSDate(d);
    } else if (formatSuggested.toLowerCase() === "iso") {
      newDate = DateTime.fromISO(d);
    } else if (formatSuggested.toLowerCase() === "dateinputiso") {
      newDate = DateTime.fromISO(d, {
        suppressSeconds: true,
        suppressMilliseconds: true,
        includeOffset: false,
      });
    } else {
      newDate = DateTime.fromFormat(d, formatSuggested);
    }
  }
  if (!newDate?.isValid) newDate = DateTime.fromFormat(d, "dd-LLL-yyyy");
  if (!newDate?.isValid) newDate = DateTime.fromJSDate(d);
  if (!newDate?.isValid) newDate = DateTime.fromJSDate(d);
  if (!newDate?.isValid) newDate = DateTime.fromISO(d);
  if (!newDate?.isValid) newDate = DateTime.fromFormat(d, "dd LLL yyyy");
  if (!newDate?.isValid) newDate = DateTime.fromFormat(d, "dd-LL-yyyy");
  if (!newDate?.isValid) newDate = DateTime.fromFormat(d, "dd/LL/yyyy");
  return newDate;
};

// export const checkValidDates = (d, formatSuggested) => {
//   let newDate;
//   if (d?.isValid) return d;
//   if (formatSuggested)
//     newDate =
//       formatSuggested.toLowerCase() === "jsdate"
//         ? new DateTime.fromJSDate(d)
//         : formatSuggested.toLowerCase() === "iso"
//         ? new DateTime.fromISO(d)
//         : new DateTime.fromFormat(d, formatSuggested);
//   if (!newDate?.isValid) newDate = new DateTime.fromFormat(d, "dd-LLL-yyyy");
//   if (!newDate?.isValid) newDate = new DateTime.fromJSDate(d);
//   if (!newDate?.isValid) newDate = new DateTime.fromJSDate(d);
//   if (!newDate?.isValid) newDate = new DateTime.fromISO(d);
//   if (!newDate?.isValid) newDate = new DateTime.fromFormat(d, "dd LLL yyyy");
//   if (!newDate?.isValid) newDate = new DateTime.fromFormat(d, "dd-LL-yyyy");
//   if (!newDate?.isValid) newDate = new DateTime.fromFormat(d, "dd/LL/yyyy");
//   return newDate;
// };

export const getISODate = (d) => {
  if (!d || d === undefined || d === "") return false;
  if (d?.isValid) return d;

  d = DateTime.fromISO(d);
  return d?.isValid ? d : null;
};

export const getValidDate = (d, formatSuggested) => {
  if (!d || d === undefined || d === "") return false;
  if (d?.isValid) return d;

  d = DateTime.fromISO(d);
  if (d?.isValid) return d;

  let newDate = formatSuggested
    ? checkValidDates(d, formatSuggested)
    : checkValidDates(d);
  return newDate.isValid ? newDate : d;
};

// export const getValidDate = (d, formatSuggested) => {
//   if (!d || d === undefined || d === "") return false;
//   if (d?.isValid) return d;
//   let newDate = formatSuggested
//     ? checkValidDates(d, formatSuggested)
//     : checkValidDates(d);
//   return newDate.isValid ? newDate : d;
// };

export const returnIsNewVsOldDate = (newDate, comp, origDate) => {
  let result = newVsOldDate(newDate, comp, origDate);

  if (result === null) {
    // let oDate = getValidDate(origDate, dateFormats.luxon);
    // let nDate = getValidDate(newDate, dateFormats.luxon);

    let oDate = getValidDate(origDate, dateFmts.htmlDatetimeInput.type);
    let nDate = getValidDate(newDate, dateFmts.htmlDatetimeInput.type);

    if (!oDate.isValid && !nDate.isValid) return null;
    if (!oDate.isValid && nDate.isValid) return newDate;
    if (oDate.isValid && !nDate.isValid) return origDate;

    return origDate;
  } else if (result === true) {
    return newDate;
  } else if (result === false) {
    return origDate;
  } else {
    return origDate;
  }
};

// export const returnIsNewVsOldDate = (newDate, comp, origDate) => {
//   let result = newVsOldDate(newDate, comp, origDate);

//   if (result === null) {
//     // let oDate = getValidDate(origDate, dateFormats.luxon);
//     // let nDate = getValidDate(newDate, dateFormats.luxon);

//     let oDate = getValidDate(origDate, dateFmts.htmlDatetimeInput.type);
//     let nDate = getValidDate(newDate, dateFmts.htmlDatetimeInput.type);

//     if (!oDate.isValid && !nDate.isValid) return null;
//     if (!oDate.isValid && nDate.isValid) return newDate;
//     if (oDate.isValid && !nDate.isValid) return origDate;

//     return origDate;
//   } else if (result === true) {
//     return newDate;
//   } else if (result === false) {
//     return origDate;
//   } else {
//     return origDate;
//   }
// };

// compare if new date is something compared to old date
export const newVsOldDate = (newDate, comp, origDate) => {
  // let oDate = getValidDate(origDate, dateFormats.luxon);
  // let nDate = getValidDate(newDate, dateFormats.luxon);
  let oDate = getValidDate(origDate, dateFmts.htmlDatetimeInput.type);
  let nDate = getValidDate(newDate, dateFmts.htmlDatetimeInput.type);

  if (!oDate.isValid && !nDate.isValid) return null;
  if (!oDate.isValid && nDate.isValid) return null;
  if (oDate.isValid && !nDate.isValid) return null;
  if (comp === "<" || comp === "lt") return nDate < oDate;
  if (comp === "<=" || comp === "lte") return nDate <= oDate;
  if (comp === ">" || comp === "gt") return nDate > oDate;
  if (comp === ">=" || comp === "gte") return nDate >= oDate;
  if (comp === "=" || comp === "eq") return nDate === oDate;
  // if (comp === "<" || comp === "lt") return oDate < nDate;
  // if (comp === "<=" || comp === "lte") return oDate <= nDate;
  // if (comp === ">" || comp === "gt") return oDate > nDate;
  // if (comp === ">=" || comp === "gte") return oDate >= nDate;
  // if (comp === "=" || comp === "eq") return oDate === nDate;
};

// export const compareDates = (origDate, newDate, comp) => {
//   let oDate = getValidDate(origDate);
//   let nDate = getValidDate(newDate);

//   if (!oDate.isValid && !nDate.isValid) return null;
//   if (!oDate.isValid && nDate.isValid) return newDate;
//   if (oDate.isValid && !nDate.isValid) return origDate;
//   if (comp === "<" || comp === "lt") return nDate < oDate ? newDate : origDate;
//   if (comp === "<=" || comp === "lte")
//     return nDate <= oDate ? newDate : origDate;
//   if (comp === ">" || comp === "gt") return nDate > oDate ? newDate : origDate;
//   if (comp === ">=" || comp === "gte")
//     return nDate >= oDate ? newDate : origDate;
//   if (comp === "=" || comp === "eq")
//     return nDate === oDate ? newDate : origDate;
// };

export const fromToDate = (d) => {
  return DateTime.fromFormat(d, "dd-LLL-yyyy");
};

export const formatDateString = (d) => {
  let newDate = getValidDate(d, dateFormats.mongodb);
  return !newDate.isValid ? newDate : newDate.toFormat(dateFormats.summaryDate);
  // return !newDate.isValid ? newDate : newDate.toFormat("dd-LLL-yyyy");
};

export const formatTime = (d) => {
  let newDate = getValidDate(d);
  return !newDate.isValid
    ? newDate
    : newDate.toLocaleString(DateTime.TIME_24_SIMPLE);
};

export const formatDate = (d) => {
  return !d.isValid ? d : d.toFormat(dateFormats.luxon);
};

// logic checks
