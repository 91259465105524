import React from "react";

const IconButton = ({ idx, icon, icons, fieldOpts, value, opts }) => {
  let ThisIcon = icons[icon];
  if (!opts?.color) opts.color = "primary";
  opts.className = "btn btn-sm " + (opts?.className ? opts.className : "");
  if (!opts?.onchange && fieldOpts?.onchange)
    opts.onClick = (e) => fieldOpts.onchange(eVal, idx, e);

  let theName = opts?.fieldName
    ? opts.fieldName
    : fieldOpts?.fieldName
    ? fieldOpts.fieldName
    : "";
  let eVal = {
    target: {
      name: theName,
      id: theName,
      value: value,
    },
  };
  return (
    <button
      // className={opts.className}
      {...opts}
      key={"legI-btn-" + icon + "-" + idx}
    >
      <span className="material-icons float-end" style={{ fontSize: "1.5em" }}>
        {ThisIcon}
      </span>
    </button>
  );
};

export default IconButton;
