// services/reportGenerator.js

import jsPDF from "jspdf";
import "jspdf-autotable";
import { DateTime } from "luxon";
// import PDF, { Text, AddPage, Line, Image, Table, Html } from 'jspdf-react'

// Date Fns is used to format the dates we receive
// from our API call
// import { format } from "date-fns";
// import { DateTime } from "luxon";
import {
  // dateFormats,
  formatValue,
} from "../../utils/common";
import { dateFormats } from "../legs/legDefinition";

// define a generatePDF function that accepts a tickets argument
const generatePDF = (currentHoliday, legs) => {
  // initialize jsPDF
  const doc = new jsPDF({ orientation: "landscape" });

  // define the columns we want and their titles
  const tableColumn = [
    "Type",
    "From City",
    "To City",
    "Start",
    "Finish",
    "Company",
    "Booking",
    "Flight",
  ];
  // define an empty array of rows
  const tableRows = [];

  // for each ticket pass all its data into an array
  legs.forEach((leg) => {
    const legData = [
      //   leg._id,
      leg.legType,
      leg?.from?.city?.label,
      leg?.to?.city?.label,
      leg?.from?.dateTime
        ? formatValue("date", leg.from.dateTime, dateFormats.pdfDateTime)
        : "",
      leg?.to?.dateTime
        ? formatValue("date", leg.to.dateTime, dateFormats.pdfDateTime)
        : "",
      leg.legType === "Transport"
        ? leg?.travelDetails?.company
        : leg?.stayDetails?.company,
      leg.legType === "Transport"
        ? leg?.travelDetails?.reservationNo
        : leg?.stayDetails?.reservationNo,
      leg.legType === "Transport" ? leg?.travelDetails?.vehicleNo : "",
      // called date-fns to format the date on the ticket
      //   format(new Date(ticket.updated_at), "yyyy-MM-dd")
    ];
    // push each tickcet's info into a row
    tableRows.push(legData);
  });

  // startY is basically margin-top
  doc.autoTable(tableColumn, tableRows, { startY: 20 });

  const dateTime = DateTime.local();
  // we use a date string to generate our filename.
  const dateStr = dateTime.toFormat("ccc-LL-LLL-HH:mm");
  // ticket title. and margin-top + margin-left
  //   doc.text("Closed tickets within the last one month.", 14, 15);
  doc.text("Travel Itinerary.", 14, 15);
  // we define the name of our PDF file.

  let reg1 = /([^A-Za-z0-9-.()])/g;
  let cleanName = currentHoliday.name.replace(reg1, "");

  doc.save(`Itinerary_${cleanName}_${dateStr}.pdf`);
};

export default generatePDF;
