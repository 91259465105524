import {
  addDays,
  formatValue,
  // dateFormats,
} from "../../utils/common";

import {
  convertDateFrom,
  convertDateTo,
  getISODate,
  isISODate,
} from "../../utils/commonDates";

// should? on unlock of leg, update based on previous linked leg ??
// should? on update of leg, update next non-locked linked leg ??

import { defaultLeg } from "./legDefinition";

// this function checks the previous leg when adding a leg and
//    adds defaults from previous leg for: city, from.dateTime, legType
//    note: legType alternates between stay & transport
export const addTravelLeg = (e, idx, legsNew, setLegsNew) => {
  let newLegs = [...legsNew];
  let newValues = {
    ...defaultLeg,
    _id: "new-" + findLatestNewLegId(newLegs),
  };

  // check leg is not 0, leg exists & default info from previous leg
  if (idx > -1 && newLegs?.[idx]) {
    let last = newLegs?.[idx];

    // default from value from previous to
    if (last?.to?.city) newValues.from.city = last.to.city;

    // default starting date from last date
    if (isISODate(last?.from?.dateTime) && !isNaN(last?.noOfDays)) {
      let newDate = addDays(last.from.dateTime, last.noOfDays);
      newValues.from.dateTime = convertDateTo(newDate, "iso", "", {});
    }

    // add stay if last item was transport
    if (last?.legType === "Transport") {
      newValues.legType = "Stay";
    } else if (last?.legType === "Stay") {
      newValues.legType = "Transport";
    }
  }

  newLegs.splice(idx + 1, 0, newValues);
  setLegsNew(newLegs);
  return newValues;
};

export const updateCurrentLeg = (
  e,
  idx,
  legsNew,
  setLegsNew,
  showLeg,
  setShowLeg
) => {
  let newVs = [...legsNew]; // new copy of current legs
  let leg = newVs[idx]; // get the current leg
  let legOrig = { ...newVs[idx] }; // get an original version of the current leg
  let fld = e.target.id;
  let value;

  if (e?.target?.type === "iso") {
    value = convertDateFrom(e?.target?.value, e.target.type, "", {});
    value = convertDateTo(value, "iso", "", {});
  } else {
    value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
  }

  // check if from location filter
  if (e?.raw) value = e.raw;

  // update subPart (return leg for the departing leg)
  newVs[idx] = updateSubPart(
    e,
    idx,
    leg,
    legsNew,
    setLegsNew,
    showLeg,
    setShowLeg
  );

  // update buffer, To Date based on From & noOfDays if required
  newVs[idx] = updateBuffer(newVs, idx, fld, leg, value);
  newVs[idx] = updateToDate(fld, leg, value);
  newVs[idx] = updateNoOfDays(fld, leg, value);
  // newVs = addRemoveReturnLeg(newVs, idx, fld, leg, value);
  // updateTotals(idx, fld, leg, value);

  if (fld.includes(".")) {
    let flds = fld.split(".");
    if (!newVs[idx][flds[0]]) newVs[idx][flds[0]] = defaultLeg[flds[0]];
    newVs[idx][flds[0]][flds[1]] = value;
  } else {
    newVs[idx][fld] = value;
  }
  if (leg !== legOrig) newVs[idx].changed = true;

  // updateBothLegs(newVs, true);
  setLegsNew(newVs);
};

export const updateLaterLegs = (e, idx, legsNew, setLegsNew) => {
  let newVs = [...legsNew];

  // if there are at least 2 legs
  if (newVs?.length > 1) {
    // loop through legs after the current one (idx)
    for (let i = idx + 1; i < newVs.length; i++) {
      // get the current leg & create a copy
      let leg = newVs[i];
      let legOrig = { ...leg };

      // skip if leg is locked or not linkToLast
      if (leg?.locked) continue;
      if (!leg.linkToLast) continue;

      // get the last linked record & skip if doesn't exist
      let lastLinked = getLastLinked(newVs, i);
      if (lastLinked === -1) continue;

      // Get values and fill empty ones
      let lastToDateTime = lastLinked.to.dateTime;
      if (!leg?.noOfDays) leg.noOfDays = 0; // update noOfDays
      leg.to = !leg.to
        ? { dateTime: leg.from.dateTime }
        : { ...leg.to, dateTime: leg.from.dateTime }; // update from Date based last toDateTime
      leg = deriveNewFromDate(lastToDateTime, leg); // update from Date based last toDateTime
      leg = updateToDate("from.dateTime", leg, leg.from.dateTime); // update To Date based on new from date

      // take the current to dateTime for the next leg from
      lastToDateTime = leg.to.dateTime;
      if (legOrig !== leg) leg.changed = true;
    }
  }

  // updateBothLegs(newVs, true);
  setLegsNew(newVs);
};

export const updateSubPart = (
  e,
  idx,
  leg,
  legsNew,
  setLegsNew,
  showLeg,
  setShowLeg
) => {
  if (e.target.id === "travelDetails.subPart") {
    let legId = addRemoveSubPart(
      e,
      idx,
      legsNew,
      setLegsNew,
      showLeg,
      setShowLeg
    );
    let returnLegId = e.target.value ? legId : "";
    leg.travelDetails = leg?.travelDetails
      ? { ...leg?.travelDetails, subPartId: returnLegId }
      : { subPartId: returnLegId };
  }
  return leg;
};

export const addRemoveSubPart = (
  e,
  idx,
  legsNew,
  setLegsNew,
  showLeg,
  setShowLeg
) => {
  let value = e.target.checked;
  let addTheLeg = value;

  let newVs = [...legsNew];
  let leg = newVs[idx];

  // check if subPartId exists and check if leg exists
  let subPartId = leg?.travelDetails?.subPartId;
  let subPartIdx = !subPartId ? null : findSubPartIndex(subPartId, legsNew);

  let legId;
  if (addTheLeg) {
    // Add return leg
    if (subPartId && subPartIdx) {
      // sub part Id exists and leg exists, show leg
      legId = addReturnLeg(e, idx, legsNew, setLegsNew);
      setShowLeg({ ...showLeg, [legId]: true });
    } else {
      // sub part Id exists but leg doesn't, add leg and read _id
      // sub part (return) doesn't exist, add leg and read _id
      legId = addReturnLeg(e, idx, legsNew, setLegsNew);
      setShowLeg({ ...showLeg, [legId]: true });
    }
  } else {
    if (subPartId && subPartIdx) {
      // sub part Id exists and leg exists, hide / remove show leg
      removeReturnLeg(subPartIdx);
    } else {
      // doesn't exist, do nothing
      // nothing to do here, removal of subPartId is done at bottom
      // sub part Id exists but leg doesn't, clear subPartId
      // nothing to do here, removal of subPartId is done at bottom
    }
  }
  return legId;
};

export const addReturnLeg = (e, idx, legsNew, setLegsNew) => {
  let newLegs = [...legsNew];
  let newValues = {
    ...defaultLeg,
    _id: "new-" + findLatestNewLegId(newLegs),
  };
  console.log("--------------------------");

  // check leg is not 0, leg exists & default info from previous leg
  if (idx > -1 && newLegs?.[idx]) {
    let last = newLegs?.[idx];
    console.log(last);
    newValues.linkToLast = false;

    // default from value from previous to
    if (last?.to?.city) newValues.from.city = last.to.city;
    if (last?.from?.city) newValues.to.city = last.from.city;

    // default starting date from last date
    if (isISODate(last?.from?.dateTime) && !isNaN(last?.noOfDays)) {
      let newDate = addDays(last.from.dateTime, last.noOfDays);
      newValues.from.dateTime = formatValue("date", newDate, "iso");
      newValues.noOfDays = last.noOfDays;
      newValues = updateToDate("noOfDays", newValues, last.noOfDays);
    }

    // add stay if last item was transport
    if (last?.legType) newValues.legType = last.legType;
  }

  newLegs.splice(idx + 1, 0, newValues);
  // updateBothLegs(newLegs, true);
  setLegsNew(newLegs);
  return newValues._id;
  // return idx + 1
};

export const removeReturnLeg = (subPartIdx, legsNew, setLegsNew) => {
  let newValues = [...legsNew];
  newValues.splice(subPartIdx, 1);

  // updateBothLegs(newValues, true);
  setLegsNew(newValues);
  // setLegsNew(newLegs);
};

// const sortReturnLeg = (subPartId) => {};

export const findSubPartIndex = (subPartId, legsNew) => {
  for (let i = 0; i < legsNew.length; i++) {
    if (legsNew[i]._id === subPartId) {
      return legsNew[i]._id;
    }
  }
  return null;
};

export const updateToDate = (fld, leg, value) => {
  if (fld === "from.dateTime" || fld === "noOfDays") {
    // if the field changing is noOfDays, update the days value,
    //   otherwise get the no of days from the existing leg
    let days = 0;
    if (fld === "noOfDays") {
      days = value;
    } else if (leg?.["noOfDays"]) {
      days = leg["noOfDays"];
    }

    // if the field changing is from.dateTime, update the date value,
    //   otherwise get the date from the existing the existing leg
    let date = "";
    if (fld === "from.dateTime") {
      date = value;
    } else if (leg?.from?.dateTime) {
      date = leg.from.dateTime;
    }

    let daysValid = !isNaN(days) || 0; // check noOfDays is valid
    let fromValid = isISODate(date); // check from date is is valid
    let newDate;

    if (daysValid && fromValid) {
      let toDate = getISODate(date);
      newDate = addDays(toDate, days); // add days to the from date
      if (newDate.isValid && leg?.to) {
        leg.to.dateTime = convertDateTo(newDate, "iso", "", {});
      }
    }
  }
  return leg;
};

// Gets the latest linked leg with a to.dateTime before the current
export const getLastLinked = (newVs, idx) => {
  // if leg is not the first
  if (idx > 0) {
    // starting at current (-1) leg, loop through all legs in reverse
    for (let i = idx - 1; i > -1; i--) {
      // and find the latest leg with link enabled and has a to.dateTime
      if (newVs[i].linkToLast && newVs[i].to.dateTime) {
        return newVs[i];
      }
    }
  }
  return -1;
};

export const updateNoOfDays = (fld, leg, value) => {
  // if updating the to dateTime, update the noOfDays duration
  if (fld === "to.dateTime") {
    if (isISODate(leg?.from?.dateTime) && isISODate(value)) {
      let fromDate = getISODate(leg.from.dateTime);
      let toDate = getISODate(value);

      if (toDate >= fromDate) {
        let diffInDays = toDate.diff(fromDate, "days");
        diffInDays = diffInDays.toObject(); //=> { months: 1 }
        leg.noOfDays = diffInDays.days;
      }
    }
  }
  return leg;
};

export const getDaysDiff = (date1, date2) => {
  // let dateType = dateFmts.htmlDatetimeInput.type;
  if (isISODate(date1) && isISODate(date2)) {
    let fromDate = getISODate(date1);
    let toDate = getISODate(date2);

    if (toDate >= fromDate) {
      let diffInDays = toDate.diff(fromDate, "days");
      diffInDays = diffInDays.toObject(); //=> { months: 1 }
      return diffInDays.days;
    }
  }
  return 0;
};

export const deriveNewFromDate = (lastToDateTime, leg) => {
  // Get New from date based on last legs to date and any buffer
  //  (new to use old as already updated the current row so buffer will only show buffer with latest dates)
  let newFromPlusBuffer = addDays(
    lastToDateTime,
    leg.bufferFromLast ? leg.bufferFromLast : 0
  );
  // let fromDateNew = formatValue("date", newFromPlusBuffer, dateFormats.fromDB);
  let fromDateNew = convertDateTo(newFromPlusBuffer, "iso", "", {});
  leg.from.dateTime = fromDateNew; // set from date as to date (with any buffer)
  return leg;
};

export const updateBuffer = (newVs, idx, fld, leg, value) => {
  let flds = ["from.dateTime", "noOfDays", "to.dateTime", "linkToLast"];

  if (idx > 0 && flds.includes(fld)) {
    let lastLinked = getLastLinked(newVs, idx);

    if (lastLinked?.to?.dateTime && leg?.from?.dateTime) {
      leg.bufferFromLast = getDaysDiff(
        lastLinked.to.dateTime,
        fld === "from.dateTime" ? value : leg.from.dateTime
      );
    }
  }
  return leg;
};

export const findLatestNewLegId = (legs) => {
  let lastId = -1;
  if (legs?.length > 0) {
    for (let i = 0; i < legs.length; i++) {
      if (legs[i]._id.includes("new")) {
        let legNewNo = legs[i]._id.replace("new-", "");
        if (!isNaN(legNewNo) && parseInt(legNewNo) > lastId)
          lastId = parseInt(legNewNo);
      }
    }
  }
  return lastId === -1 ? 1 : lastId + 1;
};
