import React, { useContext } from "react";
import HolidayContext from "../../context/holiday/holidayContext";

const HolidayAlerts = () => {
  const holidayContext = useContext(HolidayContext);
  const clearAlert = (e, alertId) => {
    holidayContext.clearHolidayAlert(alertId);
  };

  let alertStyles = {
    info: {
      text: "text-white",
      background: "primary",
      close: "btn-close-white",
    },
    success: {
      text: "text-white",
      background: "success",
      close: "btn-close-white",
    },
    warning: { text: "", background: "warning", close: "" },
    danger: {
      text: "text-white",
      background: "danger",
      close: "btn-close-white",
    },
    default: {
      text: "text-white",
      background: "primary",
      close: "btn-close-white",
    },
  };

  return (
    holidayContext?.holidayAlerts?.length > 0 &&
    holidayContext.holidayAlerts.map((alert) => {
      let style = alertStyles?.[alert.type]
        ? alertStyles[alert.type]
        : alertStyles.default;

      return (
        <div
          // className="top-0 start-50 translate-middle-x"
          className="position-fixed bottom-0 end-0 p-3"
          style={{ zIndex: 110 }}
          key={"alerts-toast-pos" + alert.id}
        >
          <div
            className={`toast align-items-center ${style.text} bg-${style.background} border-0 show`}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            key={"alerts-toast-div" + alert.id}
          >
            <div className="d-flex" key={"alerts-toast-flex" + alert.id}>
              <div className="toast-body" key={"alerts-toast-body" + alert.id}>
                {alert.msg}
              </div>
              <button
                type="button"
                className={`btn-close ${style.close} me-2 m-auto`}
                // data-bs-dismiss="toast"
                aria-label="Close"
                onClick={(e) => clearAlert(e, alert.id)}
                key={"alerts-toast-close" + alert.id}
              ></button>
            </div>
          </div>
        </div>
      );
    })
  );
};

export default HolidayAlerts;
