import React, {
  useContext,
  useRef,
  useEffect,
  useState,
  Fragment,
} from "react";
import ReactSelect from "react-select/creatable";
import AuthContext from "../../context/auth/authContext";

const UserFilter = ({ opts, value, scope, excludeValues }) => {
  const authContext = useContext(AuthContext);
  const { searchUsers, usersFound } = authContext;

  const filter = useRef("");
  const [filterSet, setFilterSet] = useState({ filters: [] });

  let filters = [
    {
      name: "users",
      dataset: usersFound,
      fields: ["name", "email"],
      values: "_id",
    },
  ];

  const getFilterList = (filters, value) => {
    let list = [];
    console.log("----1---------------");
    console.log(filters);
    console.log(value);
    for (let filter of filters) {
      // run through each of teh filters
      if (
        filter?.dataset?.length > 0 &&
        (!scope || scope.includes(filter.name))
      ) {
        // check if the dataset has records
        for (let field of filter.fields) {
          // run throug each field to be included in the list
          for (let data of filter.dataset) {
            // run through each record of the data set
            let dataLabel = data[field];
            let dataValue = data[filter.values];
            let item = { label: dataValue, value: data[filter.values] };
            if (
              dataValue &&
              !list.includes(item) &&
              excludeValues?.length &&
              !excludeValues.includes(dataValue)
            )
              list.push({ label: dataLabel, value: data[filter.values] });
          }
        }
      }
    }
    return list;
  };

  const dataHasRecords = (filterSet) => {
    for (let i = 0; i < filterSet.length; i++) {
      if (filterSet?.[i]?.dataset?.length > 0) return true;
    }
    return false;
  };

  useEffect(() => {
    if (dataHasRecords(filters)) {
      console.log(usersFound?.length);
      console.log(usersFound);
      console.log(getFilterList(filters));
      setFilterSet({ filters: getFilterList(filters) });
    }
    //eslint-disable-next-line
  }, [usersFound]);

  const handleInputChange = (e, a) => {
    if (e.length > 2) {
      console.log(e);
      searchUsers(e);
    }
  };

  return (
    <Fragment>
      <ReactSelect
        {...opts}
        className="form-control form-control-sm"
        formatCreateLabel={() => undefined}
        ref={filter}
        options={filterSet.filters}
        value={value}
        onInputChange={handleInputChange}
      />
    </Fragment>
  );
};

export default UserFilter;
