import React, { Fragment } from "react";
import LocationFilter from "../components/common/LocationFilter";
import Flatpckr from "../components/common/Flatpckr";
import { Duration } from "luxon";
import { dateFmts, dateFormats } from "../components/legs/legDefinition";
import { convertDateFromBase, getISODate } from "./commonDates";

export const dbMsg = (lvl, dbl, msg) => {
  let dbgLevel = dbl === null || dbl === undefined ? 0 : dbl;
  if (dbgLevel >= lvl) console.log(msg);
};

export const checkIfMobile = () => {
  let isMobile = false; //initiate as false
  // device detection
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      navigator.userAgent.substring(0, 4)
    )
  ) {
    isMobile = true;
  }
  return isMobile;
};

export const readData = (record, fldName) => {
  let flds = fldName.split(".");
  let data = record;
  for (let i = 0; i < flds.length; i++) {
    if (data?.[flds[i]]) {
      data = data[flds[i]];
    } else {
      return "";
    }
  }
  return data;
};

export const iterateFieldSets = (record, sections, secName, styls, k, idx) => {
  if (!sections?.[secName]?.length > 0) return "";
  return (
    <Fragment>
      {sections[secName].map((section, rowIdx) => {
        let k1 = [k, "row", rowIdx].join("-");
        if (!section?.length || !section.length)
          return (
            <div className={styls.row + " g-1"} key={k1}>
              {""}
            </div>
          );

        return (
          <div className={styls.row + " g-1"} key={k1}>
            {section.map((fldObj, colIdx) => {
              let fldDef = sections?.fieldsDef?.[fldObj.field];
              if (!fldDef)
                return console.log("Error: missing field: " + fldObj.field);

              let k2 = [k, "col", colIdx].join("-");
              let label = [k2, fldObj.field].join("-");
              fldDef = { ...fldDef, ...fldObj };
              let fldCols = fldDef?.columns ? fldDef?.columns : "auto";

              return (
                <div className={`col-${fldCols} g-1 `} key={k2}>
                  {textField(idx, label, record, fldDef, {})}
                </div>
              );
            })}
          </div>
        );
      })}
    </Fragment>
  );
};

// input functions
export const textField = (idx, labelPrefix, record, fieldDef, opts) => {
  // set the keyID, field name/id and get the value from the record
  let keyId = [labelPrefix, fieldDef.fieldName, idx].join("-");
  let fieldIdName = { name: fieldDef.fieldName, id: fieldDef.fieldName };

  // add key and field type to input options tag
  opts = {
    ...opts,
    ...fieldIdName,
    key: keyId,
    type: fieldDef.type,
    // type: "text",
    value: readData(record, fieldDef.data) || "",
  };
  // update the onchange event if no event is passed through "onchange"
  // opts.value = formatValue(fieldDef.type, fieldDef.field, fieldDef.format);
  if (opts.onchange !== undefined || opts.onchange !== null)
    opts.onChange = (e) => fieldDef.onchange(e, idx);

  // check if it's a date picker and set the date picker type
  if (fieldDef?.options && fieldDef.options.includes("showDatePicker"))
    return datePickerInput(opts, fieldDef, fieldIdName, idx);

  // set specific options if its a location select
  if (fieldDef.type === "location")
    return locationSelectInput(opts, fieldDef, fieldIdName, idx);

  // format the value differently if it's a date
  opts.value = formatValue(fieldDef.type, opts.value, fieldDef.format);
  // if ()
  if (fieldDef.field === "travelDetailsSubPart") {
    console.log(fieldDef);
    console.log(opts);
  }
  return opts.type === "checkbox"
    ? checkboxInputField(keyId, opts, fieldDef)
    : textInputField(keyId, opts, fieldDef);
};

export const textInputField = (keyId, opts, fieldDef) => (
  <div className="input-group mb-1 form-floating">
    <input
      {...opts}
      className="form-control form-control-sm"
      placeholder=""
      aria-label="Example text with button addon"
      aria-describedby="button-addon1"
    />
    <label htmlFor={keyId}>{fieldDef.fieldLabel}</label>
  </div>
);

export const checkboxInputField = (keyId, opts, fieldDef) => {
  opts.checked = opts.value;
  delete opts.value;
  return (
    <div className="form-check align-middle">
      <input
        {...opts}
        className="form-check-input"
        placeholder=""
        aria-label="Example text with button addon"
        aria-describedby="button-addon1"
      />
      <label className="form-check-label" htmlFor={opts.id}>
        {/* <label className="form-check-label" htmlFor={keyId}> */}
        {fieldDef.fieldLabel}
      </label>
    </div>
  );
};

export const locationSelectInput = (opts, fieldDef, fieldIdName, idx) => {
  delete opts.type;
  opts.placeholder = fieldDef.fieldLabel;
  opts.onChange = (e) =>
    fieldDef.onchange(
      {
        target: {
          ...fieldIdName,
          value: e.value,
        },
        raw: { ...e },
      },
      idx
    );

  return <LocationFilter opts={opts} scope={fieldDef.set} />;
  // return   <LocationFilter opts={opts} value={value} scope={fieldDef.set} />;
};

export const datePickerInput = (opts, fieldDef, fieldIdName, idx) => {
  let datePickerType = "html";
  opts.value = formatValue("datepicker", opts.value, "");
  opts.type = "date";
  opts.placeholder = datePickerType === "react" ? "" : fieldDef.fieldLabel;

  let funcs = {
    flat: (e, f, g) =>
      fieldDef.onchange(
        {
          target: { ...fieldIdName, value: e[0], type: "jsdate" },
          other: { f, g },
        },
        idx
      ),
    html: (e, f, g) =>
      fieldDef.onchange(
        {
          target: { ...fieldIdName, value: e.target.value, type: "iso" },
          other: { f, g },
        },
        idx
      ),
  };
  // opts.onChange = funcs["html"];
  opts.onChange = funcs[datePickerType];
  opts.onClearDate = (e) =>
    fieldDef.onchange(
      {
        target: {
          ...fieldIdName,
          value: "",
        },
      },
      idx
    );
  return htmlDatePicker(opts, idx);
};

// flatDatePcker(opts, idx)
export const htmlDatePicker = (opts, idx) => {
  // let dateClearClick = opts.onClearDate;
  delete opts.onClearDate;

  opts.value = !opts?.value
    ? ""
    : formatValue("date", opts.value, "iso", {
        suppressSeconds: true,
        suppressMilliseconds: true,
        includeOffset: false,
        isDatePicker: true,
      });
  return (
    <div className="input-group mb-1 form-floating">
      <input
        className="form-control form-control-sm"
        type="datetime-local"
        onChange={opts.onChange}
        value={opts.value || ""}
      />
      <label htmlFor={opts.key}>{opts.placeholder}</label>
    </div>
  );
};

export const flatDatePcker = (opts, idx) => {
  let dateClearClick = opts.onClearDate;
  delete opts.onClearDate;

  return (
    <div className="input-group">
      <Flatpckr
        options={{
          dateFormat: dateFormats.datePickerPicker,
          enableTime: "true",
          allowInput: "true",
        }}
        // date-time-enable="true"
        opts={{ ...opts, className: "form-control" }}
        type="text"
        onchange={opts.onChange}
        oncleardate={dateClearClick}
        value={opts.value}
      />
    </div>
  );
};

export const icon = (icon, fontSize, k) => {
  let opts = fontSize ? { style: { fontSize: fontSize } } : {};

  return (
    <span {...opts} key={k + "-btn-icon"} className="material-icons">
      {icon}
    </span>
  );
};

export const locationSelect = (idx, labelPrefix, record, fieldDef, opts) => {
  let keyId = [labelPrefix, fieldDef.fieldName, idx].join("-");
  let value = readData(record, fieldDef.data);

  opts = {
    ...opts,
    id: fieldDef.fieldName,
    key: keyId,
    name: fieldDef.fieldName,
    className: "form-control form-control-sm",
  };

  opts.value = value;
  if (opts.onchange !== undefined || opts.onchange !== null)
    opts.onChange = (e) => fieldDef.onchange(e, idx);

  return (
    <div className="input-group mb-3 form-floating">
      <LocationFilter opts={opts} />
    </div>
  );
};

export const checkBox = (labelPrefix, record, fieldDef, idx, opts) => {
  let keyId = [labelPrefix, fieldDef.fieldName, idx].join("-");
  let value = readData(record, fieldDef.data);

  opts = { ...opts, id: fieldDef.fieldName, key: keyId };
  opts.checked = value !== null ? value : true;

  return (
    <Fragment>
      <input
        {...opts}
        name={fieldDef.fieldName}
        type="checkbox"
        className="form-check-input mx-2"
        onChange={(e) => fieldDef.onchange(e, idx)}
      />
      {fieldDef.fieldLabel === "" ? (
        ""
      ) : (
        <label className="form-check-label" htmlFor={keyId}>
          {fieldDef.fieldLabel}
        </label>
      )}
    </Fragment>
  );
};

export const showTypeSelect = (k, idx, record, fieldDef, list) => {
  let keyId = [k, "select", idx].join("-");
  let value = readData(record, fieldDef.data);

  let opts = { id: fieldDef.fieldName, key: keyId };
  return (
    <Fragment>
      <div className="form-floating">
        <select
          className="form-select form-select-sm"
          {...opts}
          value={value ? value : "Select"}
          name={fieldDef.fieldLabel}
          onChange={(e) => fieldDef.onchange(e, idx)}
        >
          {list.map((item, i) => (
            <option key={keyId + "-" + i} value={item}>
              {item}
            </option>
          ))}
        </select>
        <label htmlFor={keyId}>{fieldDef.fieldLabel}</label>
      </div>
    </Fragment>
  );
};

export const formatValue = (fieldType, value, format, options) => {
  if (value === null || value === undefined) return "";

  switch (fieldType.toLowerCase()) {
    case "float":
      return value === "" ? "" : parseFloat(value);
    case "date":
      if (value === "") return "";
      if (options?.isDatePicker) {
        let dateFmt = dateFmts.htmlDatetimeInput;
        let fmtValue = convertDateFromBase(
          "iso",
          dateFmt.type,
          value,
          dateFmt.format,
          dateFmt.options
        );
        return fmtValue;
      } else {
        let fmtValue = convertDateFromBase("iso", "", value, format, options);
        return fmtValue;
      }
    default:
      return value;
  }
};

// application specific
export const sumValues = (total, newValue) => {
  const numOr0 = (newValue) =>
    isNaN(newValue) || newValue === "" ? 0 : parseFloat(newValue);

  let numTotal = numOr0(total);
  let numNew = numOr0(newValue);

  if (numNew === 0) {
    return numTotal > 0 ? numTotal : total;
  } else if (numTotal === 0) {
    return numNew > 0 ? numNew : total;
  } else {
    return numTotal + numNew;
  }
};

export const addDays = (start, days) => {
  let newDate;
  if (start.isValid) {
    newDate = start;
  } else {
    newDate = getISODate(start);
  }

  if (!newDate.isValid || !hasVal(days) || isNaN(days)) {
    return start;
  } else {
    let millis = Duration.fromObject({ days: days })
      .shiftTo("milliseconds")
      .toObject();
    return newDate.plus(millis.milliseconds);
  }
};

export const hasObjValue = (value) => (value?.value ? value.value : value);

export const hasObjLabel = (value) => (value?.label ? value.label : value);

export const objHasKey = (obj, key) =>
  !emptyObj(obj) && Object.prototype.hasOwnProperty.call(obj, key);

export const emptyObj = (obj) => !obj || obj === null;

export const zeroString = (val) => val === "";

export const hasVal = (val) =>
  !(val === undefined || val === null || val === "");
