import {
  GET_HOLIDAYS,
  ADD_HOLIDAY,
  UPDATE_MEMBER,
  // DELETE_HOLIDAY,
  // CLEAR_HOLIDAYS,
  UPDATE_HOLIDAY,
  GET_HOLIDAY_EDITLIST,
  CLEAR_EDITLIST,
  GET_LOCATION_REFERENCE,
  // FILTER_HOLIDAYS,
  // CLEAR_FILTER,
  SET_CURRENT_HOLIDAY,
  CLEAR_CURRENT_HOLIDAY,
  HOLIDAY_ERROR,
  CLEAR_HOLIDAY_ERRORS,
  HOLIDAY_ALERT,
  SET_HOLIDAY_ALERT,
  REMOVE_HOLIDAY_ALERT,
  REMOVE_ALL_HOLIDAY_ALERTS,
} from "../typesHolidays";

const holidayReducer = (state, action) => {
  switch (action.type) {
    case SET_HOLIDAY_ALERT:
      return {
        ...state,
        holidayAlerts: !state.holidayAlerts
          ? [action.payload]
          : [...state.holidayAlerts, action.payload],
      };
    case REMOVE_HOLIDAY_ALERT:
      return {
        ...state,
        holidayAlerts: state.holidayAlerts.filter(
          (alert) => alert.id !== action.payload
        ),
      };
    case REMOVE_ALL_HOLIDAY_ALERTS:
      return {
        ...state,
        holidayAlerts: [],
      };
    case GET_HOLIDAYS:
    case ADD_HOLIDAY:
    case UPDATE_MEMBER:
    case UPDATE_HOLIDAY:
      return {
        ...state,
        holidays: action.payload,
        loading: false,
      };
    case GET_HOLIDAY_EDITLIST:
      return {
        ...state,
        editList: action.payload,
        loading: false,
      };

    case CLEAR_EDITLIST:
      return {
        ...state,
        editList: action.payload,
        loading: false,
      };

    case GET_LOCATION_REFERENCE:
      return {
        ...state,
        countries: action.payload.countries,
        cities: action.payload.cities,
        airports: action.payload.airports,
        loading: false,
      };

    case SET_CURRENT_HOLIDAY:
      return {
        ...state,
        currentHoliday: action.payload,
        loading: false,
      };

    case CLEAR_CURRENT_HOLIDAY:
      return {
        ...state,
        currentHoliday: null,
        loading: false,
      };

    case HOLIDAY_ALERT:
    case HOLIDAY_ERROR:
      return {
        ...state,
        holidays: null,
        currentHoliday: false,
        currentDay: null,
        loading: false,
        error: action.payload,
      };

    case CLEAR_HOLIDAY_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export default holidayReducer;
