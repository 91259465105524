import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Alerts from "./components/layout/Alerts";
import About from "./components/pages/About";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/routing/PrivateRoute";
import Home from "./components/pages/Home";

import AlertState from "./context/alert/AlertState";
import AuthState from "./context/auth/AuthState";

import setAuthToken from "./utils/setAuthToken";

import HolidayState from "./context/holiday/HolidayState";
import LegState from "./context/leg/LegState";
import Holidays from "./components/pages/Holidays";
import Legs from "./components/pages/Legs";
import HolidayAlerts from "./components/holidays/HolidayAlerts";

import "./App.css";
import "./table.css";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  return (
    <AuthState>
      <HolidayState>
        <LegState>
          <AlertState>
            <Router>
              <Fragment>
                <Navbar
                  title="Holiday Planner"
                  icon="bi bi-wallet white-text"
                />
                <div className="container m-0 p-0">
                  <Alerts />
                  <HolidayAlerts />
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <PrivateRoute exact path="/holidays" component={Holidays} />
                    <PrivateRoute exact path="/legs" component={Legs} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/login" component={Login} />
                  </Switch>
                </div>
              </Fragment>
            </Router>
          </AlertState>
        </LegState>
      </HolidayState>
    </AuthState>
  );
};

export default App;
