import {
  GET_LEGS,
  CLEAR_LEGS,
  GET_LEG,
  CLEAR_LEG,
  ADD_LEG,
  // DELETE_LEG,
  UPDATE_LEG,
  // FILTER_LEGS,
  // CLEAR_FILTER,
  LEG_ERROR,
  SET_CURRENT_LEG,
  CLEAR_CURRENT_LEG,
  CLEAR_ERRORS,
} from "../typesHolidays";

const legReducer = (state, action) => {
  switch (action.type) {
    case GET_LEGS:
    case ADD_LEG:
    case UPDATE_LEG:
      return {
        ...state,
        legs: action.payload,
        loading: false,
      };

    case CLEAR_LEGS:
      return {
        ...state,
        legs: null,
        loading: false,
      };

    case GET_LEG:
      return {
        ...state,
        leg: action.payload,
        loading: false,
      };

    case CLEAR_LEG:
      return {
        ...state,
        leg: null,
        loading: false,
      };

    case SET_CURRENT_LEG:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };

    case CLEAR_CURRENT_LEG:
      return {
        ...state,
        current: null,
        loading: false,
      };

    case LEG_ERROR:
      return {
        ...state,
        legs: null,
        current: false,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default legReducer;
