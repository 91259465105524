import React, { Fragment } from "react";

import {
  checkBox,
  showTypeSelect,
  hasObjLabel,
  formatValue,
  iterateFieldSets,
} from "../../utils/common";
import { DateTime } from "luxon";

import { defaultDef, fieldDef, fieldDefTypeSelect } from "./legDefinition";
import { newVsOldDate } from "../../utils/commonDates";
import { changeCase, isEmptyString } from "../../utils/functionsCommon";
import HorizonButtons from "../common/HorizonButtons";
import SearchSites from "../common/SearchSites";
import IconButton from "../common/IconButton";
import {
  icons,
  showExpandButton,
  showLinkedButton,
  showLockedButton,
  showMoreLegButton,
} from "./legFormItems";
import Button from "../common/Button";

const statusTypes = {
  planning: {
    color: "warning",
    text: "planning",
  },
  booked: {
    color: "success",
    text: "booked",
  },
  locked: {
    color: "success",
    text: "locked",
  },
  past: {
    color: "success",
    text: "past",
  },
};

const subTypeButtons = {
  transport: ["flight", "bus", "train", "taxi", "car", "ferry", "boat"],
  stay: [],
  eat: ["restaurant", "market", "festival", "food trucks"],
  activity: ["tour", "sport", "festival", "other"],
};

const LegItem = ({
  IS_MOBILE,
  leg,
  idx,
  onLegChange,
  addLeg,
  removeLeg,
  onTypeChange,
  onLinkClick,
  showHideRow,
  bShowRow,
  showHideMoreLeg,
  bShowMoreLeg,
}) => {
  const { legType } = leg;
  const legTypeLower = legType.toLowerCase();
  const sections = fieldDef(legTypeLower, onLegChange);
  const haveMoreFields =
    JSON.stringify(sections["fieldSet"]) !==
    JSON.stringify(sections["fieldsMore"]);

  /*

  to-do:
  - date dropdown doesn't trigger the totals update for some reason.  other fields are fine
  - fix the styling for either date / location drop down or sync others
  - fix the location loading and filtering
  - fix the location default on loading
  - fix the location default on adding new leg
  - fix the reloading of the holiday values
  - make the date dropdown disappear on date click (maybe)
  - need to make add destination work if doesn't exist
  */

  const getLegStatus = (datePassed, leg) => {
    let status = "planning";
    if (datePassed) {
      status = "past";
    } else if (leg?.locked) {
      status = "locked";
    } else if (leg?.status) {
      status = leg.status;
    }
    return status;
  };

  const determineTitleText = (leg) => {
    let title;
    if (!leg?.subType || isEmptyString(leg.subType)) {
      title = leg.legType;
    } else {
      title = leg.subType;
    }
    if (leg.legType === "Transport") {
      if (leg?.travelDetails?.company)
        title += " - " + leg.travelDetails.company;
    } else if (leg.legType === "Stay") {
      if (leg?.stayDetails?.company) title += " - " + leg.stayDetails.company;
    }

    return changeCase(title, "sentence");
  };

  const showExpandTitleRow = (leg, k, idx, bShowRow) => (
    <Fragment>
      {showExpandButton(bShowRow, idx, leg._id, showHideRow)}
      <span
        className="h5"
        key={k + "-h5-type-min-title-sub"}
        onClick={(e) =>
          showHideRow({
            target: {
              value: leg._id,
            },
          })
        }
      >
        {determineTitleText(leg)}
      </span>
    </Fragment>
  );

  const showToFromTitleRow = (leg) => {
    let title;
    title = leg?.from?.city ? hasObjLabel(leg.from.city) : "";
    title += leg?.to?.city ? " to " + hasObjLabel(leg.to.city) : "";
    title +=
      leg?.legType === "Transport" && leg?.travelDetails?.vehicleNo
        ? " - " + leg.travelDetails.vehicleNo
        : "";
    return title;
  };

  const showDateTitleRow = (fldDef) => (
    <Fragment>
      {leg?.from?.dateTime
        ? formatValue(
            "date",
            leg.from.dateTime,
            fldDef?.fieldsDef?.fromDateTime?.format
          )
        : ""}
      {leg?.to?.dateTime
        ? " - " +
          formatValue(
            "date",
            leg.to.dateTime,
            fldDef?.fieldsDef?.toDateTime?.format
          )
        : ""}
    </Fragment>
  );

  const showStatusPill = (status) => (
    <div className="float-end">
      <span className={`badge rounded-pill bg-` + statusTypes[status].color}>
        {statusTypes[status].text}
      </span>
    </div>
  );

  const showInputFields = (styls, k, idx) => (
    <div className={`container border-top m-0 p-2`}>
      <div className={styls.row + " pb-1"} key={k + "-row-type"}>
        <div className={"col-6"} key={k + "-col-type"}>
          {showTypeSelect(k, idx, leg, fieldDefTypeSelect(onTypeChange), [
            "Select",
            "Transport",
            "Stay",
            "Activity",
            "Eat",
          ])}
        </div>
        <div className={"col-6 float-right g-1"} key={k + "-col-btns"}>
          <div className="float-end">
            {checkBox(k, leg, defaultDef("linkToLast", onLegChange), idx, {})}
            <SearchSites idx={idx} leg={leg} onLinkClick={onLinkClick} />
            <IconButton
              idx={idx}
              icons={icons}
              icon={"RemoveLeg"}
              fieldOpts={{ onchange: removeLeg }}
              value=""
              opts={{}}
            />
          </div>
        </div>
      </div>
      <HorizonButtons
        idx={idx}
        k={k}
        subTypeButtons={subTypeButtons}
        leg={leg}
        onLegChange={onLegChange}
      />
      {showDataFields(bShowMoreLeg)}
      <div className={styls.row + " e-1"} key={k + "-row-subpart"}>
        {/* <div className={"col-4 g-1 "} key={k + "-col-subpart"}>
          {checkBox(
            k,
            leg,
            defaultDef("travelDetailsSubPart", onLegChange),
            idx,
            {}
          )}
        </div> */}
        <div className={"col-12 g-1 "} key={k + "-col-addLeg"}>
          <div className="d-flex justify-content-center">
            <Button
              idx={idx}
              text={"Add Leg"}
              fieldOpts={{ onchange: addLeg }}
              opts={{ className: "btn-primary" }}
            />
          </div>
        </div>
        {/* <div className={"col-4 g-1 "} key={k + "-col-showMore"}>
          <div className="float-end">
            {haveMoreFields ? (
              <Fragment>
                Show {bShowMoreLeg ? "Less" : "More"}
                {showMoreLegButton(leg._id, idx, showHideMoreLeg)}
              </Fragment>
            ) : (
              ""
            )}
          </div> 
        </div>*/}
      </div>
    </div>
  );

  const showLegRow = (idx, legTypeLower, fieldSet, bShowRow) => {
    if (!legTypeLower || legTypeLower === "activity")
      legTypeLower = "transport";

    let fldDef = fieldDef(legTypeLower, onLegChange);
    let styls = { col: "col-auto g-1", row: "row" };
    let k = ["legI", idx, legTypeLower].join("-");
    let datePassed = fieldSet?.toDateTime?.field
      ? newVsOldDate(DateTime.now(), ">=", fieldSet.toDateTime.field)
      : false;

    let status = getLegStatus(datePassed, leg);

    return (
      <Fragment>
        <div
          className={`container border-top m-0 p-2 bg-light`}
          key={k + "-contain-min-title"}
        >
          <div
            className={"row px-2 align-items-center"}
            key={k + "-row-min-title"}
          >
            <div className={"col-10 g-1"} key={k + "-col-min-title"}>
              <div
                className={"row px-0 align-items-center"}
                key={k + "-row-type-min-title-sub"}
              >
                <div
                  className={"col-8 g-1 px-2"}
                  key={k + "-col-type-min-title-sub"}
                  // style={{ textOverflow: "ellipsis" }}
                  // style={{ overflow: "hidden" }}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {showExpandTitleRow(leg, k, idx, bShowRow)}
                </div>
                <div className={"col-4 g-0 "} key={k + "-col-type-min-status"}>
                  {showStatusPill(status)}
                </div>
              </div>
              <div
                className={"row px-2 align-items-center"}
                key={k + "-row-type-min-destination"}
              >
                <div
                  className={"col-12 g-1 px-2"}
                  key={k + "-col-type-min-destination"}
                >
                  {showToFromTitleRow(leg)}
                </div>
              </div>
              <div
                className={"row px-2 align-items-center"}
                key={k + "-row-type-min-date-sub"}
              >
                <div
                  className={"col-12 g-1 px-2"}
                  key={k + "-col-type-min-date-sub"}
                >
                  {showDateTitleRow(fldDef)}
                </div>
              </div>
            </div>
            <div className={"col-2 g-1 "} key={k + "-col-type-min-btn-sub"}>
              <div className="text-center">
                {showLinkedButton(
                  leg,
                  onLegChange,
                  defaultDef("linkToLast", onLegChange),
                  idx
                )}
              </div>
              <div className="text-center">
                {showLockedButton(
                  leg,
                  onLegChange,
                  defaultDef("locked", onLegChange),
                  idx
                )}
              </div>
            </div>
          </div>
        </div>
        {!bShowRow ? "" : showInputFields(styls, k, idx)}{" "}
      </Fragment>
    );
  };

  // shows all the data fields from the fieldsection
  // which defines the field grouping
  const showDataFields = (bShowMoreLeg) => {
    let styls = { col: "col-auto g-1", row: "row" };
    let k = ["legI", idx, legTypeLower].join("-");
    let secName = bShowMoreLeg ? "fieldsMore" : "fieldSet";

    return (
      <Fragment>
        {iterateFieldSets(leg, sections, secName, styls, k, idx)}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {showLegRow(
        idx,
        !legTypeLower ? "transport" : legTypeLower,
        fieldDef(legTypeLower, onLegChange),
        bShowRow
      )}
    </Fragment>
  );
};

export default LegItem;
